import { cn } from "~/lib/utils";



const CustomTextarea = (props: any) => {
	return (
		<textarea
			{...props}
			className={cn(
				"rounded border-border bg-inputBackground leading-none text-text focus:border-orange focus:outline-none focus:ring-0",
				props.width,
			)}
		/>
	);
};

export default CustomTextarea;
