import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

import ListProfilesService from "../../../api/services/ListProfilesService";
import {
	LANGUAGE_OPTION_ENGLISH,
	LANGUAGE_OPTION_GERMAN,
	REACT_QUERY_KEYS,
} from "../../../common/constants";
import { sortObjectList } from "../../../common/helper";
import Autocomplete from "../../../components/Autocomplete";
import CustomLabel from "../../../components/CustomLabel";
import CustomSelect from "../../../components/CustomSelect";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import type IProfileList from "../../../interfaces/common/IProfileList";
import type ISelectOption from "../../../interfaces/common/ISelectOption";
import { type IEmployeeProfile } from "../../../interfaces/EmployeeProfile";
import useLogoutOnUnauthorized from "~/hooks/useLogoutOnUnauthorized";

interface IProfileMap {
	de: ISelectOption[];
	en: ISelectOption[];
}

interface ISelectionLists {
	employees: ISelectOption[];
	languages: ISelectOption[];
	variants: IProfileMap;
}

const calculateVariants = (profilesOfUser: IProfileList[]) => {
	let variantMap: IProfileMap = { de: [], en: [] };
	for (let index = 0; index < profilesOfUser.length; index++) {
		const profile = profilesOfUser[index];
		variantMap[profile.language as keyof IProfileMap].push({
			id: profile.variantId,
			name: profile.variantName || "Hauptprofil",
		});
	}
	sortObjectList(variantMap.en);
	sortObjectList(variantMap.de);
	return variantMap;
};

const calculateLanguages = (variants: IProfileMap) => {
	const availableLanguages = [];
	variants.de.length > 0 && availableLanguages.push(LANGUAGE_OPTION_GERMAN);
	variants.en.length > 0 && availableLanguages.push(LANGUAGE_OPTION_ENGLISH);
	return availableLanguages;
};

const EmployeeSelect = (props: {
	employeeProfile: IEmployeeProfile | null;
}) => {
	const { t } = useTranslation();
  const logoutOnUnauthorized = useLogoutOnUnauthorized();

	const [, setSearchParams] = useSearchParams();

	const [selectionLists, setSelectionLists] = useState<ISelectionLists>({
		employees: [],
		languages: [],
		variants: { de: [], en: [] },
	});

	const [activeEmployee, setActiveEmployee] = useState<ISelectOption>({
		id: "",
		name: "",
	});
	const [activeLanguage, setActiveLanguage] = useState<ISelectOption>({
		id: "",
		name: "",
	});
	const [activeVariant, setActiveVariant] = useState<ISelectOption>({
		id: "",
		name: "",
	});

	useEffect(() => {
		setActiveEmployee({
			id: props.employeeProfile?.id || "",
			name: props.employeeProfile?.id || "",
		});
		setActiveLanguage({
			id: props.employeeProfile?.language || "",
			name: props.employeeProfile?.language || "",
		});
		setActiveVariant({
			id: props.employeeProfile?.variantId || "",
			name: props.employeeProfile?.variantName || "",
		});
	}, [
		props.employeeProfile?.variantId,
		props.employeeProfile?.variantName,
		props.employeeProfile?.language,
		props.employeeProfile?.id,
	]);

	const getListProfilesOfUserQuery = useQuery({
		queryKey: [
			REACT_QUERY_KEYS.GET_PROFILES_OF_USER,
			activeEmployee.id,
			props.employeeProfile?.id,
		],
		queryFn: () => ListProfilesService.getProfilesOfUser(activeEmployee.id),
		onSuccess: (profilesOfUser: IProfileList[]) => {
			const newVariants = calculateVariants(profilesOfUser);
			const availableLanguages = calculateLanguages(newVariants);
      newVariants.de.sort((a, b) => a.name.localeCompare(b.name));
      newVariants.en.sort((a, b) => a.name.localeCompare(b.name));
			setSelectionLists({
				...selectionLists,
				languages: availableLanguages,
				variants: newVariants,
			});
			const newEmployeeId = profilesOfUser[0]?.id;
			if (props.employeeProfile?.id !== newEmployeeId) {
				setActiveVariant(newVariants.de[0]);
				setSearchParams({
					userId: activeEmployee.id,
					language: activeLanguage.id,
					variant: newVariants.de[0].id,
				});
			} else {
				setActiveVariant({
					id: props.employeeProfile?.variantId || "",
					name: props.employeeProfile?.variantName || "",
				});
			}
		},
		onError: (error) => {
			console.error(error);
			logoutOnUnauthorized(error);
		},
		enabled: activeEmployee.id !== "",
		refetchOnWindowFocus: false,
	});

	const getListProfiles = useQuery({
		queryKey: REACT_QUERY_KEYS.GET_PROFILES,
		queryFn: () => ListProfilesService.getProfiles(),
		onSuccess: (data) => {
			const employees: ISelectOption[] = data.map((employee) => {
				return {
					id: employee.id,
					name: employee.id,
				};
			});
      employees.sort((a, b) => a.name.localeCompare(b.name));
			setSelectionLists({
				employees: employees,
				languages: [LANGUAGE_OPTION_ENGLISH, LANGUAGE_OPTION_GERMAN],
				variants: { de: [], en: [] },
			});
		},
		onError: (error) => {
      console.error(error);
			logoutOnUnauthorized(error);
    },
		refetchOnWindowFocus: false,
		retry: 4,
	});

	const setEmployeeHandler = async (value: string) => {
		const newEmployee = selectionLists.employees.find(
			(employee) => employee.id === value,
		);
		if (newEmployee) {
			setActiveEmployee(newEmployee);
			setActiveLanguage(LANGUAGE_OPTION_GERMAN);
		}
	};

	const setLanguageHandler = (value: string) => {
		if (activeEmployee.id !== "") {
			const newVariant = selectionLists.variants[value as "de" | "en"][0];
			setActiveLanguage(
				value === "de" ? LANGUAGE_OPTION_GERMAN : LANGUAGE_OPTION_ENGLISH,
			);
			setActiveVariant(newVariant);
			setSearchParams({
				userId: activeEmployee.id,
				language: value,
				variant: newVariant.id,
			});
		}
	};

	const setVariantHandler = (value: string) => {
		const newVariant = selectionLists.variants[
			activeLanguage.id as keyof IProfileMap
		].find((variant) => variant.name.toLowerCase() === value.toLowerCase());
		if (newVariant) {
			setActiveVariant(newVariant);
			setSearchParams({
				userId: activeEmployee.id,
				language: activeLanguage.id,
				variant: newVariant.id,
			});
		}
	};

	return (
		<div className="flex flex-col items-start justify-end space-y-3">
			{(getListProfilesOfUserQuery.isLoading || getListProfiles.isLoading) && (
				<LoadingSpinner />
			)}
			<div>
				<CustomLabel
					label={t("misc.employeeSelect.name") + ":"}
					className="mb-1 ml-1"
				/>
				<Autocomplete
					disabled={
						getListProfilesOfUserQuery.isLoading || getListProfiles.isLoading
					}
					placeholderClosed={"Suche Mitarbeiter"}
					placeholderOpened={"Wähle Mitarbeiter"}
					placeholderNotFound={"Keine Mitarbeiter gefunden"}
					options={selectionLists.employees}
					onChange={setEmployeeHandler}
					value={activeEmployee?.name || ""}
          className="max-w-[200px] overflow-hidden"
				/>
			</div>
			<div>
				<CustomLabel
					label={t("misc.employeeSelect.language") + ":"}
					className="mb-1 ml-1"
				/>
				<CustomSelect
					disabled={
						getListProfilesOfUserQuery.isLoading || getListProfiles.isLoading
					}
					onChange={setLanguageHandler}
					value={activeLanguage?.id || ""}
					placeholder="Sprache"
					options={selectionLists.languages}
				/>
			</div>
			<div>
				<CustomLabel
					label={t("misc.employeeSelect.variant") + ":"}
					className="mb-1 ml-1"
				/>
				<Autocomplete
					disabled={
						getListProfilesOfUserQuery.isLoading || getListProfiles.isLoading
					}
					placeholderClosed={"Suche Variante"}
					placeholderOpened={"Wähle Variante"}
					placeholderNotFound={"Keine Variante gefunden"}
					options={
						selectionLists.variants[activeLanguage.id as "de" | "en"] || []
					}
					onChange={setVariantHandler}
					value={activeVariant?.name || ""}
          className="max-w-[200px] overflow-hidden"
				/>
			</div>
		</div>
	);
};

export default EmployeeSelect;
