import { Fragment, useCallback, useEffect, useState } from "react";
import update from "immutability-helper";

import { type Tag } from "../common/customTypes";
import { orderTags } from "../common/helper";
import type ISelectOption from "../interfaces/common/ISelectOption";
import Autocomplete from "./Autocomplete";
import { TagPill } from "./CustomTagPill";

const TagPillAutocomplete = (props: {
	options: ISelectOption[];
	tags: string[];
	placeholderClosed: string;
	placeholderOpened: string;
	placeholderNotFound: string;
	width?: string;
	setTags?: any;
}) => {
	const [tagsOrdered, setTagsOrdered] = useState<Tag[]>(orderTags(props.tags));

	useEffect(() => {
		setTagsOrdered(orderTags(props.tags));
	}, [props.tags]);

	const handleSelect = (value: string) => {
		const optionValue = props.options.find(
			(option) => option.name.toLowerCase() === value.toLowerCase(),
		);
		const newOrderedTags = [
			...tagsOrdered,
			{ id: tagsOrdered.length, text: optionValue?.name as string },
		];
		setTagsOrdered(newOrderedTags);
		const newTags = [
			...tagsOrdered,
			{ id: tagsOrdered.length, text: optionValue?.name as string },
		];
		props.setTags(newTags.map((tag) => tag.text));
	};

	const removeTag = (indexToRemove: number) => {
		props.setTags([
			...(props.tags as []).filter(
				(_: any, index: number) => index !== indexToRemove,
			),
		]);
		setTagsOrdered([
			...(tagsOrdered as []).filter(
				(_: any, index: number) => index !== indexToRemove,
			),
		]);
	};

	const moveTagPill = useCallback((dragIndex: number, hoverIndex: number) => {
		setTagsOrdered((prevTagPill: Tag[]) =>
			update(prevTagPill, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prevTagPill[dragIndex] as Tag],
				],
			}),
		);
	}, []);

	return (
		<Fragment>
			<div className="mb-2 flex flex-row flex-wrap rounded border border-dashed px-2 pt-2">
				{tagsOrdered.length > 0 ? (
					tagsOrdered.map((tag, i) => (
						<TagPill
							key={tag.id}
							index={i}
							id={tag.id}
							text={tag.text}
							moveTagPill={moveTagPill}
							onClick={removeTag}
						/>
					))
				) : (
					<div className="h-8" />
				)}
			</div>
			<Autocomplete
				placeholderClosed={props.placeholderClosed}
				placeholderOpened={props.placeholderOpened}
				placeholderNotFound={props.placeholderNotFound}
				options={props.options}
				onChange={handleSelect}
				value={""}
        className={props.width}
			/>
		</Fragment>
	);
};

export default TagPillAutocomplete;
