import { type ChangeEvent, type ChangeEventHandler } from "react";
import { type AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { type MutateOptions } from "react-query";

import CustomLabel from "../../../components/CustomLabel";
import { CustomTagPillInput } from "../../../components/CustomTagPillInput";
import { Heading } from "../../../components/Typography";
import { Label } from "../../../components/ui/label";
import { type IIntroduction } from "../../../interfaces/EmployeeProfile/sections";
import { Input } from "~/components/ui/input";

interface IntroductionProps {
	introduction: IIntroduction;
	setIntroduction: (introduction: IIntroduction) => void;
	imageFile: any;
	setImageFile: (imageFile: any) => void;
	postImage: (
		variables: File,
		options?:
			| MutateOptions<AxiosResponse<any, any>, unknown, File, unknown>
			| undefined,
	) => void;
	deleteImage: () => void;
}
const Introduction = (props: IntroductionProps) => {
	const {
		introduction,
		setIntroduction,
		imageFile,
		setImageFile,
		postImage,
		deleteImage,
	} = props;
	const { t } = useTranslation();
	const imageUrl = imageFile ? URL.createObjectURL(imageFile) : null;

	const postImageHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			setImageFile(file);
			postImage(file);
		}
	};

	const deleteImageHandler = () => {
		deleteImage();
	};

	const handleInputChange = (event: any, key: "position" | "name") => {
		setIntroduction({ ...introduction, [key]: event.target.value });
	};

	return (
		<div>
			<div className="my-3 ml-3">
				<Heading level={"2"}>{t("introduction.title")}</Heading>
			</div>
			<div className="bg-atMidGrey bg-formTileBackground p-10 sm:rounded-lg">
				<div className="sm:grid sm:grid-cols-6 sm:gap-4">
					<div className="col-span-3">
						{!imageUrl && (
							<span className="mb-1 flex items-center justify-center text-[12px] text-text">
								{t("introduction.profilePicture.label")}
							</span>
						)}
						<div className="flex h-full w-full justify-center">
							{!imageUrl && (
								<Label className="flex h-full w-60 cursor-pointer flex-col items-center justify-center rounded-md border-2 border-dashed hover:border-gray-300 hover:bg-gray-100">
									<div className="flex flex-col items-center justify-center pt-7">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-12 w-12 text-gray-400 group-hover:text-gray-600"
											viewBox="0 0 20 20"
											fill="currentColor"
										>
											<path
												fillRule="evenodd"
												d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
												clipRule="evenodd"
											/>
										</svg>
										<p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
											{t("introduction.profilePicture.description")}
										</p>
									</div>
									<Input
										type="file"
										className="opacity-0"
										name="imageFile"
										onChange={(event: any) => postImageHandler(event)}
									/>
								</Label>
							)}
							{imageUrl && (
								<div className="flex flex-row items-center">
									<img
										src={imageUrl}
										alt="Profile pic"
										className="h-full w-60"
									/>
									<button
										type="button"
										className="rounded-full px-4 py-2 font-bold text-text hover:text-orange"
										onClick={deleteImageHandler}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											strokeWidth="1.5"
											stroke="currentColor"
											className="h-6 w-6 rounded-full"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
											/>
										</svg>
									</button>
								</div>
							)}
						</div>
					</div>
					<div className="col-span-3 space-y-2">
						<div className="flex flex-col">
							<CustomLabel
								label={t("introduction.position.label")}
								tooltip={t("introduction.position.tooltip")}
							/>
							<Input
								value={introduction.position}
                className="w-1/2"
								onChange={(e: ChangeEvent<HTMLInputElement>) =>
									handleInputChange(e, "position")
								}
								type="text"
								placeholder={t("introduction.position.placeholder")}
                />
						</div>
						<div className="flex flex-col">
							<CustomLabel
								label={t("introduction.name.label")}
								tooltip={t("introduction.name.tooltip")}
                />
							<Input
								value={introduction.name}
                className="w-1/2"
								onChange={(e: ChangeEvent<HTMLInputElement>) =>
									handleInputChange(e, "name")
								}
								type="text"
								placeholder={t("introduction.name.placeholder")}
							/>
						</div>
						<div className="flex flex-col">
							<CustomLabel
								label={t("introduction.heroSkill.label")}
								tooltip={t("introduction.heroSkill.tooltip")}
							/>
							<CustomTagPillInput
								tags={introduction.heroSkill}
								setTags={(e: string[]) =>
									setIntroduction({
										...introduction,
										heroSkill: e,
									})
								}
								placeholder={t("introduction.heroSkill.placeholder")}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Introduction;
