import DatePicker from "react-datepicker";

import { EMPTY_EDUCATION_ENTRY } from "../../../common/dummyEntries";

import "react-datepicker/dist/react-datepicker.css";

import { useCallback, useState, type ChangeEvent } from "react";
import {
	DndContext,
	DragOverlay,
	MouseSensor,
	useSensor,
	useSensors,
	type DragEndEvent,
	type DragStartEvent,
} from "@dnd-kit/core";
import {
	arrayMove,
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { GripVerticalIcon, Trash2Icon } from "lucide-react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "~/components/ui/accordion";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";

import { getLastDayOfMonth } from "../../../common/helper";
import CustomCheckbox from "../../../components/CustomCheckbox";
import CustomLabel from "../../../components/CustomLabel";
import CustomTextarea from "../../../components/CustomTextarea";
import { Heading } from "../../../components/Typography";
import CalendarIcon from "../../../icons/calendarIcon.svg";
import { type IEducation } from "../../../interfaces/EmployeeProfile/sections";

interface EducationProps {
	education: IEducation[];
	setEducation: (education: IEducation[]) => void;
}
const Education = ({ education, setEducation }: EducationProps) => {
	const { t } = useTranslation();

	// Memoize sensors to avoid unnecessary re-initializations
	const sensors = useSensors(useSensor(MouseSensor));

	const [overlayPE, setOverlayPE] = useState<IEducation | null>(null);
	const [accordionOpen, setAccordionOpen] = useState<string | undefined>(
		undefined,
	);

	// Utility function to update state immutably
	const updateEducationEntry = useCallback(
		(index: number, updatedFields: Partial<IEducation>) => {
			const newState = [...education];
			newState[index] = { ...newState[index], ...updatedFields };
			setEducation(newState);
		},
		[education, setEducation],
	);

	const handleDateChange = useCallback(
		(date: Date | null, key: "endDate" | "startDate", index: number) => {
			if (!date) return;

			const newDate = new Date(
				date.getTime() - date.getTimezoneOffset() * 60000,
			);
			const updatedDate =
				key === "endDate" ? getLastDayOfMonth(newDate) : newDate;
			updateEducationEntry(index, { [key]: updatedDate });
		},
		[updateEducationEntry],
	);

	const handleInputChange = useCallback(
		(
			event: ChangeEvent<HTMLInputElement>,
			key: keyof IEducation,
			index: number,
		) => {
			updateEducationEntry(index, { [key]: event.target.value });
		},
		[updateEducationEntry],
	);

	const handleAddEducationEntry = useCallback(() => {
		setEducation([
			...education,
			{
				...EMPTY_EDUCATION_ENTRY,
				startDate: null,
				endDate: null,
				id: uuidv4(),
			},
		]);
	}, [education, setEducation]);

	const handleDeleteEducationEntry = useCallback(
		(index: number) => {
			if (education.length === 1) {
				setEducation([{ ...EMPTY_EDUCATION_ENTRY }]);
			} else {
				setEducation(education.filter((_, i) => i !== index));
			}
		},
		[education, setEducation],
	);

	const handleCheckbox = useCallback(
		(checked: boolean, index: number) => {
			updateEducationEntry(index, { untilToday: checked, endDate: null });
		},
		[updateEducationEntry],
	);

	const handleDragStart = useCallback(
		(event: DragStartEvent) => {
			const activePE = education.find(
				(educationEntry) => educationEntry.id === event.active.id,
			);
			setOverlayPE(activePE ?? null);
		},
		[education],
	);

	const handleDragEnd = useCallback(
		(event: DragEndEvent) => {
			const { active, over } = event;
			if (over && active.id !== over.id) {
				const activeIndex = education.findIndex(
					(educationEntry) => educationEntry.id === active.id,
				);
				const overIndex = education.findIndex(
					(educationEntry) => educationEntry.id === over.id,
				);
				setEducation(arrayMove(education, activeIndex, overIndex));
			}
			setOverlayPE(null);
		},
		[education, setEducation],
	);

	return (
		<div>
			<div className="my-3 ml-3">
				<Heading level={"2"}>{t("education.title")}</Heading>
			</div>
			<div className="bg-atMidGrey flex flex-col bg-formTileBackground p-10 sm:rounded-lg">
				<Accordion
					type="single"
					value={accordionOpen}
					onValueChange={setAccordionOpen}
					collapsible
				>
					<DndContext
						onDragStart={handleDragStart}
						onDragEnd={handleDragEnd}
						sensors={sensors}
					>
						<SortableContext
							items={education.map((educationEntry) => educationEntry.id)}
							strategy={verticalListSortingStrategy}
						>
							{education.map((educationEntry, educationEntryIndex) => {
								return (
									<EduAccordionItem
										key={educationEntry.id}
										educationEntry={educationEntry}
										educationEntryIndex={educationEntryIndex}
										handleDateChange={handleDateChange}
										handleInputChange={handleInputChange}
										handleCheckbox={handleCheckbox}
										handleDeleteEducationEntry={handleDeleteEducationEntry}
									/>
								);
							})}
						</SortableContext>

						{typeof document !== "undefined" &&
							createPortal(
								<DragOverlay>
									{overlayPE && (
										<EduAccordionItem
											educationEntry={overlayPE}
											educationEntryIndex={-1}
											isOverlay
										/>
									)}
								</DragOverlay>,
								document.body,
							)}
					</DndContext>
				</Accordion>
				<Button className="mt-4" onClick={handleAddEducationEntry}>
					{t("education.addButton.label")}
				</Button>
			</div>
		</div>
	);
};

const EduAccordionItem = ({
	educationEntry,
	educationEntryIndex,
	isOpen = false,
	handleDateChange = () => {},
	handleInputChange = () => {},
	handleCheckbox = () => {},
	handleDeleteEducationEntry = () => {},
	isOverlay = false, // Default to false
}: {
	educationEntry: IEducation;
	educationEntryIndex: number;
	isOpen?: boolean;
	handleDateChange?: (
		date: Date | null,
		key: "endDate" | "startDate",
		index: number,
	) => void;
	handleInputChange?: (
		event: ChangeEvent<HTMLInputElement>,
		key: keyof IEducation,
		index: number,
	) => void;
	handleCheckbox?: (checked: boolean, index: number) => void;
	handleDeleteEducationEntry?: (index: number) => void;
	isOverlay?: boolean;
}) => {
	const { t } = useTranslation();

	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({
			id: educationEntry.id,
			disabled: isOpen,
		});
	const style = {
		transition,
		transform: CSS.Transform.toString(transform),
	};

	const calculateAccordionItemTitle = useCallback(
		(educationEntry: IEducation) => {
			const startDate = educationEntry.startDate?.getFullYear()
				? educationEntry.startDate?.getFullYear()
				: "-";
			const educationEntryDegree = educationEntry.degree
				? educationEntry.degree
				: "-";
			if (startDate === "-" && educationEntryDegree === "-") {
				return t("misc.accordion.noTitle");
			}
			return `${startDate} ${educationEntryDegree}`;
		},
		[t],
	);

	return (
		<div className="flex w-full flex-row gap-3" ref={setNodeRef} style={style}>
			<Button
				variant="ghost"
				className="mt-2 h-auto cursor-grab p-1"
				{...attributes}
				{...listeners}
				disabled={isOpen}
			>
				<span className="sr-only">Ausbildung bewegen</span>
				<GripVerticalIcon className="h-6 text-secondary-foreground/50" />
			</Button>
			{isOverlay ? (
				<div className="border bg-formTileBackground p-3 text-left text-base text-white">
					{calculateAccordionItemTitle(educationEntry)}
				</div>
			) : (
				<>
					<AccordionItem
						value={educationEntryIndex.toString()}
						className="w-full"
					>
						<AccordionTrigger className="w-full text-left">
							{calculateAccordionItemTitle(educationEntry)}
						</AccordionTrigger>
						<EduAccordionContent
							educationEntry={educationEntry}
							educationEntryIndex={educationEntryIndex}
							handleDateChange={handleDateChange}
							handleInputChange={handleInputChange}
							handleCheckbox={handleCheckbox}
						/>
					</AccordionItem>
					<Button
						size="icon"
						variant="destructive"
						className="mt-3 flex h-8 w-8 items-center justify-around text-white"
						onClick={() => handleDeleteEducationEntry(educationEntryIndex)}
					>
						<Trash2Icon className="h-5 w-5" />
					</Button>
				</>
			)}
		</div>
	);
};

const EduAccordionContent = ({
	educationEntry,
	educationEntryIndex,
	handleDateChange,
	handleInputChange,
	handleCheckbox,
}: {
	educationEntry: IEducation;
	educationEntryIndex: number;
	handleDateChange: (
		date: Date | null,
		key: "endDate" | "startDate",
		index: number,
	) => void;
	handleInputChange: (
		event: ChangeEvent<HTMLInputElement>,
		key: keyof IEducation,
		index: number,
	) => void;
	handleCheckbox: (checked: boolean, index: number) => void;
}) => {
	const { t } = useTranslation();

	return (
		<AccordionContent>
			<div
				className="grid grid-cols-6 space-y-2"
				key={educationEntryIndex}
			>
				<div className="col-span-6">
					<CustomLabel
						label={t("education.date.label")}
						tooltip={t("education.date.tooltip")}
					/>
					<div className="items-center space-y-2 sm:flex sm:flex-row sm:space-x-2 sm:space-y-0">
						<div className="relative">
							{/* TODO: Fix date is null issues! */}
							<DatePicker
								showIcon
								icon={<img src={CalendarIcon} alt={"Calendaricon"} />}
								className="rounded border-border bg-inputBackground leading-none text-text focus:border-orange focus:outline-none focus:ring-0"
								selected={educationEntry.startDate}
								onChange={(date: Date | null) =>
									handleDateChange(date, "startDate", educationEntryIndex)
								}
								dateFormat="MMMM yyyy"
								showMonthYearPicker
								selectsStart
								startDate={educationEntry.startDate ?? new Date()}
								endDate={educationEntry.endDate ?? new Date()}
								placeholderText={t("education.date.startDatePlaceholder")}
							/>
						</div>
						<span className="text-text sm:mx-4">-</span>
						<div>
							<DatePicker
								disabled={educationEntry.untilToday}
								showIcon
								icon={<img src={CalendarIcon} alt={"Calendaricon"} />}
								className="rounded border-border bg-inputBackground leading-none text-text focus:border-orange focus:outline-none focus:ring-0 disabled:opacity-40"
								selected={educationEntry.endDate}
								onChange={(date: Date | null) =>
									handleDateChange(date, "endDate", educationEntryIndex)
								}
								dateFormat="MMMM yyyy"
								showMonthYearPicker
								selectsEnd
								startDate={educationEntry.startDate ?? new Date()}
								endDate={educationEntry.endDate ?? new Date()}
								minDate={educationEntry.startDate ?? new Date()}
								placeholderText={t("education.date.endDatePlaceholder")}
							/>
						</div>
						<CustomCheckbox
							label="Bis Heute"
							checked={educationEntry.untilToday}
							setChecked={(checked: boolean) =>
								handleCheckbox(checked, educationEntryIndex)
							}
						/>
					</div>
				</div>
				<div className="col-span-6">
					<CustomLabel
						label={t("education.degree.label")}
						tooltip={t("education.degree.tooltip")}
					/>
					<Input
						value={educationEntry.degree}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							handleInputChange(e, "degree", educationEntryIndex)
						}
						type="text"
						placeholder={t("education.degree.placeholder")}
            className="w-1/2"
            />
				</div>
				<div className="col-span-6">
					<CustomLabel
						label={t("education.institution.label")}
						tooltip={t("education.institution.tooltip")}
            />
					<Input
						value={educationEntry.institution}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							handleInputChange(e, "institution", educationEntryIndex)
						}
						type="text"
						placeholder={t("education.institution.placeholder")}
            className="w-1/2"
					/>
				</div>
				<div className="col-span-6">
					<CustomLabel
						label={t("education.description.label")}
						tooltip={t("education.description.tooltip")}
					/>
					<div className="flex flex-col">
						<CustomTextarea
							value={educationEntry.description}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								handleInputChange(e, "description", educationEntryIndex)
							}
							rows={7}
							placeholder={t("education.description.placeholder")}
              width="w-5/6"
						/>
					</div>
				</div>
			</div>
		</AccordionContent>
	);
};

export default Education;
