import { useMsal } from "@azure/msal-react";

const useLogoutOnUnauthorized = () => {
	const { instance } = useMsal();

	const logoutOnUnauthorized = (error: any) => {
		if (typeof error === "object" && error !== null && "response" in error) {
			if (error.response.status === 401) {
				instance.logout();
			}
		}
	};

	return logoutOnUnauthorized;
};

export default useLogoutOnUnauthorized;