import { useCallback, useMemo, useState, type ChangeEvent } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import {
	INDUSTRY_OPTIONS_ENGLISH,
	INDUSTRY_OPTIONS_GERMAN,
} from "../../../common/constants";
import { EMPTY_JOB_EXPERIENCE } from "../../../common/dummyEntries";
import { getLastDayOfMonth } from "../../../common/helper";
import Autocomplete from "../../../components/Autocomplete";
import CustomCheckbox from "../../../components/CustomCheckbox";
import CustomLabel from "../../../components/CustomLabel";
import CustomTextarea from "../../../components/CustomTextarea";
import { Heading } from "../../../components/Typography";
import CalendarIcon from "../../../icons/calendarIcon.svg";
import { type IJobExperience } from "../../../interfaces/EmployeeProfile/sections";

import "react-datepicker/dist/react-datepicker.css";

import {
	DndContext,
	DragOverlay,
	MouseSensor,
	useSensor,
	useSensors,
	type DragEndEvent,
	type DragStartEvent,
} from "@dnd-kit/core";
import {
	arrayMove,
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { GripVerticalIcon, Trash2Icon } from "lucide-react";
import { createPortal } from "react-dom";

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "~/components/ui/accordion";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";

interface JobExperienceProps {
	jobExperiences: IJobExperience[];
	setJobExperiences: (jobExperiences: IJobExperience[]) => void;
}
const JobExperience = ({
	jobExperiences,
	setJobExperiences,
}: JobExperienceProps) => {
	const { t, i18n } = useTranslation();

	// Memoize sensors to avoid unnecessary re-initializations
	const sensors = useSensors(useSensor(MouseSensor));
	const [overlayPE, setOverlayPE] = useState<IJobExperience | null>(null);
	const [accordionOpen, setAccordionOpen] = useState<string | undefined>(
		undefined,
	);

	// Memoize industry options based on language
	const activeIndustryOptions = useMemo(() => {
		return i18n.language === "de-DE"
			? INDUSTRY_OPTIONS_GERMAN
			: INDUSTRY_OPTIONS_ENGLISH;
	}, [i18n.language]);

	// Utility function to update state immutably
	const updateJobExperience = useCallback(
		(index: number, updatedFields: Partial<IJobExperience>) => {
			const newState = [...jobExperiences];
			newState[index] = { ...newState[index], ...updatedFields };
			setJobExperiences(newState);
		},
		[jobExperiences, setJobExperiences],
	);

	const handleDateChange = useCallback(
		(date: Date | null, key: "endDate" | "startDate", index: number) => {
			if (!date) return;

			const newDate = new Date(
				date.getTime() - date.getTimezoneOffset() * 60000,
			);
			const updatedDate =
				key === "endDate" ? getLastDayOfMonth(newDate) : newDate;
			updateJobExperience(index, { [key]: updatedDate });
		},
		[updateJobExperience],
	);

	const handleInputChange = useCallback(
		(
			event: ChangeEvent<HTMLInputElement>,
			key: keyof IJobExperience,
			index: number,
		) => {
			updateJobExperience(index, { [key]: event.target.value });
		},
		[updateJobExperience],
	);

	const handleCheckbox = useCallback(
		(checked: boolean, index: number) => {
			updateJobExperience(index, { untilToday: checked, endDate: null });
		},
		[updateJobExperience],
	);

	const handleSelectIndustry = useCallback(
		(value: string, index: number) => {
			const selectedIndustry =
				activeIndustryOptions.find(
					(option) => option.name.toLowerCase() === value.toLowerCase(),
				)?.name || "";
			updateJobExperience(index, { industry: selectedIndustry });
		},
		[activeIndustryOptions, updateJobExperience],
	);

	const handleDeleteJob = useCallback(
		(index: number) => {
			if (jobExperiences.length === 1) {
				setJobExperiences([{ ...EMPTY_JOB_EXPERIENCE }]);
			} else {
				setJobExperiences(jobExperiences.filter((_, i) => i !== index));
			}
		},
		[jobExperiences, setJobExperiences],
	);

	const handleAddJob = useCallback(() => {
		setJobExperiences([
			...jobExperiences,
			{
				...EMPTY_JOB_EXPERIENCE,
				startDate: null,
				endDate: null,
				id: uuidv4(),
			},
		]);
	}, [jobExperiences, setJobExperiences]);

	const handleDragStart = useCallback(
		(event: DragStartEvent) => {
			const activePE = jobExperiences.find((job) => job.id === event.active.id);
			setOverlayPE(activePE ?? null);
		},
		[jobExperiences],
	);

	const handleDragEnd = useCallback(
		(event: DragEndEvent) => {
			const { active, over } = event;
			if (over && active.id !== over.id) {
				const activeIndex = jobExperiences.findIndex(
					(job) => job.id === active.id,
				);
				const overIndex = jobExperiences.findIndex((job) => job.id === over.id);
				setJobExperiences(arrayMove(jobExperiences, activeIndex, overIndex));
			}
			setOverlayPE(null);
		},
		[jobExperiences, setJobExperiences],
	);

	return (
		<>
			<div className="my-3 ml-3">
				<Heading level={"2"}>{t("jobExperience.title")}</Heading>
			</div>
			<div className="bg-atMidGrey flex flex-col bg-formTileBackground p-10 sm:rounded-lg">
				<Accordion
					type="single"
					value={accordionOpen}
					onValueChange={setAccordionOpen}
					collapsible
				>
					<DndContext
						onDragStart={handleDragStart}
						onDragEnd={handleDragEnd}
						sensors={sensors}
					>
						<SortableContext
							items={jobExperiences.map((job) => job.id)}
							strategy={verticalListSortingStrategy}
						>
							{jobExperiences.map((job, jobIndex) => (
								<JEAccordionItem
									key={job.id}
									job={job}
									jobIndex={jobIndex}
									handleDateChange={handleDateChange}
									handleInputChange={handleInputChange}
									handleCheckbox={handleCheckbox}
									handleSelectIndustry={handleSelectIndustry}
									handleDeleteJob={handleDeleteJob}
								/>
							))}
						</SortableContext>

						{typeof document !== "undefined" &&
							createPortal(
								<DragOverlay>
									{overlayPE && (
										<JEAccordionItem job={overlayPE} jobIndex={-1} isOverlay />
									)}
								</DragOverlay>,
								document.body,
							)}
					</DndContext>
				</Accordion>
				<Button className="mt-4" onClick={handleAddJob}>
					{t("jobExperience.addButton.label")}
				</Button>
			</div>
		</>
	);
};

const JEAccordionItem = ({
	job,
	jobIndex,
	isOpen = false,
	handleDateChange = () => {},
	handleInputChange = () => {},
	handleCheckbox = () => {},
	handleSelectIndustry = () => {},
	handleDeleteJob = () => {},
	isOverlay = false, // Default to false
}: {
	job: IJobExperience;
	jobIndex: number;
	isOpen?: boolean;
	handleDateChange?: (
		date: Date | null,
		key: "endDate" | "startDate",
		index: number,
	) => void;
	handleInputChange?: (
		event: ChangeEvent<HTMLInputElement>,
		key: keyof IJobExperience,
		index: number,
	) => void;
	handleCheckbox?: (checked: boolean, index: number) => void;
	handleSelectIndustry?: (value: string, index: number) => void;
	handleDeleteJob?: (index: number) => void;
	isOverlay?: boolean;
}) => {
	const { t } = useTranslation();
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({
			id: job.id,
			disabled: isOpen,
		});
	const style = {
		transition,
		transform: CSS.Transform.toString(transform),
	};

	const calculateAccordionItemTitle = useCallback(
		(job: IJobExperience) => {
			const startDate = job.startDate?.getFullYear()
				? job.startDate?.getFullYear()
				: "-";
			const jobTitle = job.jobTitle ? job.jobTitle : "-";
			if (startDate === "-" && jobTitle === "-") {
				return t("misc.accordion.noTitle");
			}
			return `${startDate} ${jobTitle}`;
		},
		[t],
	);

	return (
		<div className="flex w-full flex-row gap-3" ref={setNodeRef} style={style}>
			<Button
				variant="ghost"
				className="mt-2 h-auto cursor-grab p-1"
				{...attributes}
				{...listeners}
				disabled={isOpen}
			>
				<span className="sr-only">Joberfahrung bewegen</span>
				<GripVerticalIcon className="h-6 text-secondary-foreground/50" />
			</Button>
			{isOverlay ? (
				<div className="border bg-formTileBackground p-3 text-left text-base text-white">
					{calculateAccordionItemTitle(job)}
				</div>
			) : (
				<>
					<AccordionItem value={jobIndex.toString()} className="w-full">
						<AccordionTrigger className="w-full text-left">
							{calculateAccordionItemTitle(job)}
						</AccordionTrigger>
						<JEAccordionContent
							job={job}
							jobIndex={jobIndex}
							handleDateChange={handleDateChange}
							handleInputChange={handleInputChange}
							handleCheckbox={handleCheckbox}
							handleSelectIndustry={handleSelectIndustry}
						/>
					</AccordionItem>
					<Button
						size="icon"
						variant="destructive"
						className="mt-3 flex h-8 w-8 items-center justify-around text-white"
						onClick={() => handleDeleteJob(jobIndex)}
					>
						<Trash2Icon className="h-5 w-5" />
					</Button>
				</>
			)}
		</div>
	);
};

const JEAccordionContent = ({
	job,
	jobIndex,
	handleDateChange,
	handleInputChange,
	handleCheckbox,
	handleSelectIndustry,
}: {
	job: IJobExperience;
	jobIndex: number;
	handleDateChange: (
		date: Date | null,
		key: "endDate" | "startDate",
		index: number,
	) => void;
	handleInputChange: (
		event: ChangeEvent<HTMLInputElement>,
		key: keyof IJobExperience,
		index: number,
	) => void;
	handleCheckbox: (checked: boolean, index: number) => void;
	handleSelectIndustry: (value: string, index: number) => void;
}) => {
	const { t, i18n } = useTranslation();

	return (
		<AccordionContent>
			<div className="grid grid-cols-6 space-y-2">
				<div className="col-span-6">
					<CustomLabel
						label={t("jobExperience.date.label")}
						tooltip={t("jobExperience.date.tooltip")}
					/>
					<div className="items-center space-y-2 sm:flex sm:flex-row sm:space-x-2 sm:space-y-0">
						<div className="relative">
							<DatePicker
								showIcon
								icon={<img src={CalendarIcon} alt="Calendaricon" />}
								className="rounded border-border bg-inputBackground leading-none text-text focus:border-orange focus:outline-none focus:ring-0"
								selected={job.startDate}
								onChange={(date: Date | null) =>
									handleDateChange(date, "startDate", jobIndex)
								}
								dateFormat="MMMM yyyy"
								showMonthYearPicker
								selectsStart
								startDate={job.startDate ?? new Date()}
								endDate={job.endDate ?? new Date()}
								placeholderText={t("jobExperience.date.startDatePlaceholder")}
							/>
						</div>
						<span className="text-text sm:mx-4">-</span>
						<div className="relative">
							<DatePicker
								disabled={job.untilToday}
								showIcon
								icon={<img src={CalendarIcon} alt="Calendaricon" />}
								className="rounded border-border bg-inputBackground leading-none text-text focus:border-orange focus:outline-none focus:ring-0 disabled:opacity-40"
								selected={job.endDate}
								onChange={(date: Date | null) =>
									handleDateChange(date, "endDate", jobIndex)
								}
								dateFormat="MMMM yyyy"
								showMonthYearPicker
								selectsEnd
								startDate={job.startDate ?? new Date()}
								endDate={job.endDate ?? new Date()}
								minDate={job.startDate ?? new Date()}
								placeholderText={t("jobExperience.date.endDatePlaceholder")}
							/>
						</div>
						<CustomCheckbox
							label="Bis Heute"
							checked={job.untilToday}
							setChecked={(checked: boolean) =>
								handleCheckbox(checked, jobIndex)
							}
						/>
					</div>
				</div>
				<div className="col-span-6">
					<CustomLabel
						label={t("jobExperience.jobTitle.label")}
						tooltip={t("jobExperience.jobTitle.tooltip")}
					/>
					<Input
						value={job.jobTitle}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							handleInputChange(e, "jobTitle", jobIndex)
						}
						type="text"
						placeholder={t("jobExperience.jobTitle.placeholder")}
						className="w-1/3"
					/>
				</div>
				<div className="col-span-6">
					<CustomLabel
						label={t("jobExperience.industry.label")}
						tooltip={t("jobExperience.industry.tooltip")}
					/>
					<Autocomplete
						className="w-1/3"
						placeholderClosed={t("overview.industries.label")}
						placeholderOpened={t("overview.industries.label")}
						placeholderNotFound={t("overview.industries.label")}
						options={
							i18n.language === "de-DE"
								? INDUSTRY_OPTIONS_GERMAN
								: INDUSTRY_OPTIONS_ENGLISH
						}
						value={job.industry || ""}
						onChange={(value: string) => handleSelectIndustry(value, jobIndex)}
					/>
				</div>
				<div className="col-span-6">
					<CustomLabel
						label={t("jobExperience.company.label")}
						tooltip={t("jobExperience.company.tooltip")}
					/>
					<Input
						className="w-1/3"
						value={job.companyName}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							handleInputChange(e, "companyName", jobIndex)
						}
						placeholder={t("jobExperience.company.placeholder")}
					/>
				</div>
				<div className="col-span-6">
					<div className="flex flex-col">
						<CustomLabel
							label={t("jobExperience.description.label")}
							tooltip={t("jobExperience.description.tooltip")}
						/>
						<CustomTextarea
							value={job.description}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								handleInputChange(e, "description", jobIndex)
							}
							rows={7}
							placeholder={t("jobExperience.description.placeholder")}
              width="w-5/6"
						/>
					</div>
				</div>
			</div>
		</AccordionContent>
	);
};

export default JobExperience;
