import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";

import type ISelectOption from "../interfaces/common/ISelectOption";
import { cn } from "../lib/utils";
import { Button } from "./ui/button";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "./ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";

const Autocomplete = (props: {
	placeholderClosed: string;
	placeholderOpened: string;
	placeholderNotFound: string;
	options: ISelectOption[];
	onChange: (value: string) => void;
	value: string;
	useId?: boolean;
	disabled?: boolean;
	className?: string;
}) => {
	const {
		placeholderClosed,
		placeholderOpened,
		placeholderNotFound,
		options,
		onChange,
		value,
		useId,
		className: width = "w-full",
		disabled = false,
	} = props;
	const [open, setOpen] = React.useState(false);

	const searchOptions = (
		value: string | undefined,
		options: ISelectOption[],
		placeholderClosed: string,
	) => {
		if (value) {
			const newVal = options.find((option) => {
				if (useId) {
					return option.id === value;
				} else {
					return option.name === value;
				}
			});
			return newVal?.name;
		}
		return placeholderClosed;
	};

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger
				asChild
				className="border-border bg-inputBackground hover:bg-formTileBackground hover:text-text"
				disabled={disabled}
			>
				<Button
					variant="outline"
					role="combobox"
					className={cn("w-auto justify-between text-base text-text pl-3", width)}
				>
					<span className="overflow-hidden">{searchOptions(value, options, placeholderClosed)}</span>
					<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-full p-0">
				<Command>
					<CommandInput
						placeholder={`${placeholderOpened}`}
						className="focus:border-orange focus:ring-orange"
					/>
					<CommandList>
						<CommandEmpty>{`${placeholderNotFound}`}</CommandEmpty>
						<CommandGroup>
							{options.map((option) => (
								<CommandItem
									className="hover:bg-formTileBackground hover:text-text"
									key={option.id}
									value={option.name}
									onSelect={(currentValue) => {
										onChange(currentValue === value ? "" : currentValue);
										setOpen(false);
									}}
								>
									<Check
										className={cn(
											"mr-2 h-4 w-4",
											value === option.name ? "opacity-100" : "opacity-0",
										)}
									/>
									{option.name}
								</CommandItem>
							))}
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
};

export default Autocomplete;
