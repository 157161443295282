import { ChangeEvent, useState } from "react";
import { type AxiosResponse } from "axios";
import { PlusIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";

import ProfileService from "../../../api/services/ProfileService";
import {
	LANGUAGE_OPTION_ENGLISH,
	LANGUAGE_OPTION_GERMAN,
	REACT_QUERY_KEYS,
} from "../../../common/constants";
import CustomSelect from "../../../components/CustomSelect";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../../../components/ui/dialog";
import { toast } from "../../../components/ui/use-toast";
import { type IEmployeeProfile } from "../../../interfaces/EmployeeProfile";
import ActionButton from "./ActionButton";
import { Input } from "~/components/ui/input";

/**
 * This function matches anything that is not a letter, number, hyphen, or underscore
 * @param input
 * @returns
 */
const containsInvalidCharacters = (input: string): boolean => {
	const regex = /[^a-zA-Z0-9-_]/;
	return regex.test(input);
};

interface INewProjectParams {
	variantName: string;
	language: "de" | "en";
}
const NewProjectModal = (props: { employeeProfile: IEmployeeProfile }) => {
	const { employeeProfile } = props;
	const [project, setProject] = useState<INewProjectParams>({
		language: "de",
		variantName: "",
	});
	const [error, setError] = useState(false);
	const [open, setOpen] = useState(false);
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	const [, setSearchParams] = useSearchParams();

	const postProfileMutation = useMutation({
		mutationFn: () => {
			const newEmployeeProfile = { ...employeeProfile };
			newEmployeeProfile.variantName = project.variantName;
			newEmployeeProfile.variantId = project.variantName;
			newEmployeeProfile.language = project.language;
			return ProfileService.postProfileVariant(newEmployeeProfile);
		},
		onSuccess: (response: AxiosResponse<any, any>) => {
			setOpen(false);
			toast({
				title: t("fetching.postProfileMutation.onSuccess.title"),
			});
			queryClient.invalidateQueries(REACT_QUERY_KEYS.GET_PROFILES_OF_USER);
			setSearchParams({
				userId: employeeProfile.id,
				language: employeeProfile.language,
				variant: response.data.metadata.variantId,
			});
		},
		onError: () => {
			toast({
				title: t("fetching.postProfileMutation.onError.title"),
				description: t("fetching.postProfileMutation.onError.description"),
			});
		},
	});

	const createProfile = () => {
		if (project.variantName.includes(" ")) {
			setError(true);
		} else {
			const response = postProfileMutation.mutate();
			if (response !== undefined) {
				setOpen(false);
			}
		}
	};

	const handleInputChange = (event: any) => {
		const newValue = event.target.value;
		if (containsInvalidCharacters(newValue)) {
			setError(true);
		} else {
			setError(false);
		}
		setProject({ ...project, variantName: newValue });
	};

	const handleLanguageChange = (language: "de" | "en") => {
		setProject({ ...project, language: language });
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger>
				<ActionButton
					tooltip={t("profileActions.create.tooltip")}
					Icon={PlusIcon}
				/>
			</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>{t("misc.newProfileModal.title")}</DialogTitle>
					<DialogDescription>
						{t("misc.newProfileModal.description")}
					</DialogDescription>
				</DialogHeader>
				<CustomSelect
					onChange={handleLanguageChange}
					value={project.language}
					placeholder="Sprache"
					options={[LANGUAGE_OPTION_ENGLISH, LANGUAGE_OPTION_GERMAN]}
				/>
				<div className="absolute right-10 top-3">
					{postProfileMutation.isLoading && <LoadingSpinner />}
				</div>
				<div className="flex flex-row">
					<Input
						type="text"
						placeholder={"Projektname"}
						onChange={(event: ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
						value={project.variantName}
					/>
					<button
						className="ml-5 h-10 w-32 rounded bg-orange text-white disabled:opacity-50"
						type="button"
						disabled={error}
						onClick={() => createProfile()}
					>
						Erstellen
					</button>
				</div>
				{error ? (
					<div className="h-3 text-xs text-red-600">
						{t("misc.newProfileModal.error")}
					</div>
				) : (
					<div className="h-3" />
				)}
			</DialogContent>
		</Dialog>
	);
};

export default NewProjectModal;
